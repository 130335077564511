import w from "../utils/window";
import {
  ACTIVE_COOKIE_GROUPS_COOKIE_NAME_ONETRUST,
  ONETRUST_LOADED_EVENT,
  BASE_URL,
} from "../constants";
import { onetrustStyle } from "./onetrustStyle";
import { OneTrustLoaded } from "../utils/onetrust";
import { CookieGroupType } from "../types";
import { logToSplunk } from "../utils/log";
import { normalize } from "../utils/normalize";

/**
 *
 * @see OneTrust documentation https://community.cookiepro.com/s/article/UUID-730ad441-6c4d-7877-7f85-36f1e801e8ca?topicId=0TO1Q000000ssbSWAQ
 */
const insertOneTrustScript = (
  src: string,
  cookieGroup: CookieGroupType,
  description: string
): void => {
  if (!description) {
    console.warn("Please provide a description of why you need this script.");
  }

  function appendScript() {
    w?.OneTrust?.InsertScript(src, "head", null, null, cookieGroup);
  }

  if (w) {
    if (w.OneTrust) {
      appendScript();
    } else {
      w.addEventListener(ONETRUST_LOADED_EVENT, appendScript);
    }
  }
};

/*
This script is used in order to render the CookieConsentCheck function inline,
to be able to do the cookie check as quick as possible and inject third party scripts much faster.

When implementing OneTrust, we need to adjust this to be able to compare cookie group with active groups in OneTrust.
*/
const injectPriorityOneTrustScript = (
  group: CookieGroupType,
  scriptSrc: string,
  description: string
): string => {
  if (!description) {
    console.warn("Please provide a description of why you need this script:", scriptSrc);
  }

  return `
    (() => {
      const consentedGroups = document.cookie.split(';').find(str => str.match('${ACTIVE_COOKIE_GROUPS_COOKIE_NAME_ONETRUST}='));
      if (consentedGroups) {
        const group = ${JSON.stringify(group)};
        const consented = consentedGroups.includes(group);
        if (consented) {
          const script = document.createElement("script");
          script.src = "${scriptSrc}";
          script.async = true;
          document.head.appendChild(script);
        }
      }
    })()
  `;
};

const isConsented = (
  cookieGroup: CookieGroupType | CookieGroupType[],
  description: string
): boolean => {
  if (!description) {
    console.warn("Please provide a description of why you need this script.");
  }

  const activeGroups = consentedCookieGroups();

  if (Array.isArray(cookieGroup)) {
    return cookieGroup.every((group) => activeGroups.includes(group));
  } else {
    return activeGroups.some((group) => group === cookieGroup);
  }
};

const onceConsented = (
  cookieGroup: CookieGroupType | CookieGroupType[],
  description: string
): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (w && isConsented(cookieGroup, description)) {
      resolve();
    } else {
      w?.addEventListener(
        ONETRUST_LOADED_EVENT,
        () => {
          if (isConsented(cookieGroup, description)) {
            try {
              resolve();
            } catch (e) {
              // In a SPA environment, the callback might throw error when objects are undefined or missing in DOM.
              console.warn(`b2x-cookie-consent failed executing consent callback`, e);
              reject();
            }
          }
        },
        {
          once: true,
        }
      );
    }
  });
};

const setOneTrustConsentedValue = (value: string, date: Date): void => {
  const encodedValue = encodeURIComponent(value);
  document.cookie = `${ACTIVE_COOKIE_GROUPS_COOKIE_NAME_ONETRUST}=${encodedValue};expires=${date.toUTCString()};path=/;SameSite=Lax`;
};

/* Relying on cookies to not have to deal with race conditions and promises */
const consentedCookieGroups = (): string[] => {
  const groups = document.cookie
    .split(";")
    .find((x) => x.trim().startsWith("consentedCookieGroups="))
    ?.split("=")[1];

  if (!groups) {
    return [];
  }

  const decodedGroups = decodeURIComponent(groups)?.split(",");
  return decodedGroups.filter((group) => !!group);
};

const oneTrustGetConsentedValue = (): string => {
  const groups = consentedCookieGroups();

  if (groups) {
    return `${groups}`;
  }

  return "C0001";
};

const openOneTrustPreferences = (): void => {
  if (w?.OneTrust) {
    w?.OneTrust?.ToggleInfoDisplay();
  }
};

const initCookieConsentStyleOverrides = (): void => {
  const styleId = "cookie-consent-overrides";
  if (!w || document.getElementById(styleId)) return;

  const style = document.createElement("style");
  style.id = styleId;
  style.textContent = onetrustStyle;
  document.head.appendChild(style);
};

const appendOneTrustScript = (src: string, token: string) => {
  const script = document.createElement("script");
  script.async = true;
  script.src = src;
  script.dataset.domainScript = token;
  script.dataset.documentLanguage = "true";

  document.head.appendChild(script);
};

const oneTrustInit = (token: string, src = BASE_URL): void => {
  if (typeof w?.OptanonWrapper !== "undefined" || w === undefined) {
    return;
  }

  const triggerLoad = () => {
    const newEv = new CustomEvent(ONETRUST_LOADED_EVENT);
    w?.dispatchEvent(newEv);
  };

  let original = (w?.OnetrustActiveGroups || "").split(",");

  function OptanonWrapper() {
    triggerLoad();

    const current = (w?.OnetrustActiveGroups || "").split(",");
    const activeGroups = current.filter((group) => group).join(",");

    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    date.setDate(date.getDate() - 1);

    setOneTrustConsentedValue(activeGroups, date);

    const currentCookiesGroups = normalize(current);
    const originalCookiesGroups = normalize(original);

    logToSplunk(currentCookiesGroups, originalCookiesGroups);

    const shouldReload = originalCookiesGroups && currentCookiesGroups !== originalCookiesGroups;

    if (shouldReload) {
      location.reload();
    }

    original = current;
  }

  w.OptanonWrapper = OptanonWrapper;

  if (OneTrustLoaded()) {
    triggerLoad();
  }

  initCookieConsentStyleOverrides();
  appendOneTrustScript(src, token);
};

export {
  oneTrustInit,
  openOneTrustPreferences,
  isConsented,
  onceConsented,
  insertOneTrustScript,
  injectPriorityOneTrustScript,
  consentedCookieGroups,
  oneTrustGetConsentedValue,
};
